import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import {Box,} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import logo from "../assets/ensoll.png";
import {getWeightFromSearch} from "../api/weight";
import {useParams} from "react-router-dom";

const DetailNoAuth = () => {
    const [data, setData] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        getWeightFromSearch(id)
            .then((res) => {
                setData(res.data.weight)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <div>
            <section style={{
                background: 'linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <a href="/" style={{
                    width: '100%',
                    padding: '30px 0 0 0 ',
                    justifyContent: 'center',
                    display: 'flex'
                }}>
                    <img
                        alt=""
                        width="110px"
                        height="55px"
                        src={logo}
                        style={{
                            cursor: 'pointer', width: '200px',
                            height: '120px', backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            borderRadius: '10px', padding: '20px'
                        }}
                    />
                </a>
                <div className="forms">
                    <div className="form-wrapper is-active">
                        <Box m="20px">
                            <Grid container rowSpacing={1}
                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                <Box style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    borderRadius: '10px',
                                    width: '100%'
                                }}>
                                    <Box style={{
                                        padding: '20px',
                                        maxWidth: '100%',
                                        overflow: 'auto',
                                        height: '70vh'
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            display: 'grid',
                                            justifyContent: 'space-between',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gridGap: '20px',
                                            margin: 'auto'
                                        }}>
                                            <div className="input-block">
                                                <label>Blockchain ID</label>
                                                <input id="name" value={data.blockchain_id}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Κάδος</label>
                                                <input id="serial_num" value={data.serial_num}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Ανακυκλώσιμο Υλικό</label>
                                                <input id="type" value={data.type}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Ημερομηνία</label>
                                                <input id="date" value={data.date}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Ώρα</label>
                                                <input id="time" value={data.time}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>RFID</label>
                                                <input id="rfid" value={data.rfid}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Χρήστης</label>
                                                <input id="user" value={data.user}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Βάρος (kg)</label>
                                                <input id="weight" value={data.weight}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                            <div className="input-block">
                                                <label>Πόντοι Επιβράβευσης</label>
                                                <input id="points" value={data.points}
                                                       type="text" disabled
                                                       required/>
                                            </div>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DetailNoAuth;
