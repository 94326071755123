import React, {useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import logo from "../../assets/ensoll-bg.png";
import {userSignUp} from "../../api/user";
import {useNavigate} from "react-router-dom";
import CustomModal from "../CustomModal";
import Modal from "react-bootstrap/Modal";

const Signup = () => {
    const [values, setValues] = useState({
        email: '',
        password: ''
    });
    const [gdprChecked, setGdprChecked] = useState(false); // GDPR checkbox state
    const [modalIsOpen, setIsOpen] = useState(false);

    const {email, password} = values;

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };

    const handleGdprChange = event => {
        setGdprChecked(event.target.checked);
    };

    const toggleGdprText = () => {
        setIsOpen(!modalIsOpen);
    };

    const navigate = useNavigate();
    const clickSubmit = event => {
        event.preventDefault();
        if (!gdprChecked) {
            toast.error("Παρακαλούμε διαβάστε και αποδεχτείτε την πολιτική GDPR πριν την εγγραφή.");
            return;
        }
        userSignUp(values)
            .then((res) => {
                toast.success(`Επιτυχής Εγγραφή`);
                setTimeout(()=>{
                    window.location.reload(false);
                }, 2000);
            })
            .catch((err) => {
                toast.error(err.response.data.error)
            });

    };

    return (
        <form className="form form-signup">
            <fieldset>
                <img
                    alt="logo"
                    style={{maxWidth: "250px", height: "auto", padding: "20px"}}
                    src={logo}
                />
                <div className="input-block" style={{width: '230px'}}>
                    <label>E-mail</label>
                    <input
                        onChange={handleChange('email')}
                        value={email}
                        type="email"
                        placeholder="E-mail"
                        required
                    />
                </div>
                <div className="input-block" style={{width: '230px'}}>
                    <label>Κωδικός</label>
                    <input
                        onChange={handleChange('password')}
                        value={password}
                        type="password"
                        placeholder="Κωδικός"
                        required
                    />
                </div>

                {/* GDPR Consent Checkbox and Link */}
                <div className="gdpr-block" style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    textAlign: 'left',
                    width: '230px'
                }}>
                    <input
                        type="checkbox"
                        id="gdpr-checkbox"
                        checked={gdprChecked}
                        onChange={handleGdprChange}
                        style={{marginRight: '8px'}}
                    />
                    <label htmlFor="gdpr-checkbox" style={{
                        lineHeight: '1.2', alignSelf: "start",
                        fontSize: "14px",
                        color: "rgb(115,115,115)"
                    }}>
                        Έχω διαβάσει και αποδέχομαι την{' '}
                        <span onClick={toggleGdprText} style={{
                            color: 'blue',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>
                            πολιτική GDPR
                        </span>
                    </label>
                </div>


                {/*/!* GDPR Policy Text *!/*/}
                {/*{showGdprText && (*/}
                {/*   */}
                {/*)}*/}
            </fieldset>
            <button type="button" onClick={clickSubmit} className="btn-signup">Εγγραφή</button>
            <Modal show={modalIsOpen}
                   onHide={toggleGdprText}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <CustomModal title='Συμμόρφωση με το GDPR' closeModal={toggleGdprText}>
                    <div className="gdpr-text" style={{
                        marginTop: '10px',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        fontSize: '0.85rem',
                        color: '#333',
                        backgroundColor: '#f9f9f9',
                    }}>
                        <p style={{marginBottom: '8px'}}>
                            Με την εγγραφή σας, συμφωνείτε με την επεξεργασία των προσωπικών σας
                            δεδομένων σε συμμόρφωση με τον Γενικό Κανονισμό για την Προστασία
                            Δεδομένων (GDPR). Αυτό περιλαμβάνει:
                        </p>
                        <ul style={{paddingLeft: '20px', marginBottom: '8px'}}>
                            <li><strong>Συλλογή Δεδομένων:</strong> Συλλέγουμε μόνο τα απαραίτητα
                                προσωπικά δεδομένα, συμπεριλαμβανομένων του email και του κωδικού
                                σας, για τη δημιουργία του λογαριασμού σας.
                            </li>
                            <li><strong>Σκοπός Επεξεργασίας:</strong> Τα δεδομένα σας
                                χρησιμοποιούνται αποκλειστικά για τη δημιουργία του λογαριασμού σας
                                και την παροχή προσωποποιημένων υπηρεσιών.
                            </li>
                            <li><strong>Δικαίωμα Πρόσβασης:</strong> Έχετε το δικαίωμα να ζητήσετε
                                πρόσβαση στα προσωπικά σας δεδομένα οποιαδήποτε στιγμή.
                            </li>
                            <li><strong>Δικαίωμα Διαγραφής:</strong> Μπορείτε να ζητήσετε τη
                                διαγραφή των δεδομένων σας από τα συστήματά μας οποιαδήποτε στιγμή.
                            </li>
                            <li><strong>Ασφάλεια Δεδομένων:</strong> Εφαρμόζουμε ισχυρά μέτρα
                                ασφαλείας για να διασφαλίσουμε την προστασία των δεδομένων σας.
                            </li>
                            <li><strong>Δικαίωμα Ανάκλησης Συγκατάθεσης:</strong> Μπορείτε να
                                ανακαλέσετε τη συγκατάθεσή σας για την επεξεργασία δεδομένων
                                οποιαδήποτε στιγμή, αν και αυτό μπορεί να περιορίσει τη
                                λειτουργικότητα του λογαριασμού σας.
                            </li>
                        </ul>
                        <p>
                            Για περισσότερες πληροφορίες, παρακαλούμε ανατρέξτε στην <a
                            href="/privacy-policy" target="_blank"
                            style={{color: 'blue', textDecoration: 'underline'}}>Πολιτική
                            Απορρήτου</a> ή επικοινωνήστε μαζί μας.
                        </p>

                    </div>
                </CustomModal>
            </Modal>
        </form>

    );
};

export default Signup;
