import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import {Box, IconButton, NativeSelect, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import React, {useEffect, useState} from "react";
import {tokens} from "../theme";
import {MRT_Localization_EL} from 'material-react-table/locales/el';
import {retreiveWeights, updateWeight} from "../api/weight";
import {toast} from "react-toastify";
import useAuth from "../hooks/useAuth";
import 'dayjs/locale/en-gb';
import Grid from "@mui/material/Unstable_Grid2";
import {getTableTotals, retrievePieData, retrievePieDataSimpleUser} from "../api/system";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import FormControl from "@mui/material/FormControl";
import * as XLSX from 'xlsx'; // Import XLSX
import {BsFiletypeXlsx} from "react-icons/bs";
import CustomModal from "./CustomModal";
import Modal from "react-bootstrap/Modal";
import CustomPieChart from "../components/CustomPieChart";
import TableComponent from "../components/ TableComponent";

const columnHelper = createMRTColumnHelper();

const Entries = () => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('all');
    const {user, setUser} = useAuth();
    const [initPieData, setInitPieData] = useState({});
    const [columnFilters, setColumnFilters] = useState([]);

    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {

        retreiveWeights(selectedPeriod).then((res) => {
            setData(res.data);
            columnFilters.filter(item => item.id === 'date')[0] = null
        });
    }, [selectedPeriod]);

    useEffect(() => {
        if (columnFilters.filter(item => item.id === 'date')[0] &&
            (columnFilters.filter(item => item.id === 'date')[0].value[0] ||
                columnFilters.filter(item => item.id === 'date')[0].value[1])) {

            setSelectedPeriod('all');
        }


    }, [columnFilters.filter(item => item.id === 'date')[0]]);


    const columns = [
        columnHelper.accessor('user', {
            header: 'Χρήστης',
            id: 'user',
            enableEditing: false,
        }),
        columnHelper.accessor('rfid', {
            header: 'RFID',
            id: 'rfid',
            enableEditing: false,
        }),
        columnHelper.accessor('serial_num', {header: 'Κάδος', enableColumnFilter: true}),
        columnHelper.accessor('type', {header: 'Ανακυκλώσιμο Υλικό'}),
        columnHelper.accessor((row) => new Date(row.date).toLocaleDateString('el-GR').replace(/\//g, '/'),
            {
                header: 'Ημερομηνία',
                id: 'date',
                enableEditing: false,
                filterVariant: 'date-range',
                filterFn: (row, id, filterValue) => {
                    function greaterThanOrEqualTo(row, id, value) {
                        const cellValue = row.original[id];
                        return value === '' || value === undefined || new Date(cellValue)
                            .setHours(0, 0, 0, 0) >= new Date(value).setHours(0, 0, 0, 0);
                    }
    
                    function lessThanOrEqualTo(row, id, value) {
                        const cellValue = row.original[id];
                        return value === '' || value === undefined || new Date(cellValue)
                            .setHours(0, 0, 0, 0) <= new Date(value).setHours(0, 0, 0, 0);
                    }
    
                    return (
                        ((['', undefined].includes(filterValue[0]) ||
                                greaterThanOrEqualTo(row, id, filterValue[0])) &&
                            ((!isNaN(+filterValue[0]) &&
                                    !isNaN(+filterValue[1]) &&
                                    +filterValue[0] > +filterValue[1]) ||
                                (['', undefined].includes(filterValue[1]) ||
                                    lessThanOrEqualTo(row, id, filterValue[1]))))
                    );
                }
            }),
        // Disable filtering for 'Ωρα'
        columnHelper.accessor('time', {header: 'Ωρα', enableColumnFilter: false}),
        
        // Disable filtering for 'Βάρος (kg)'
        columnHelper.accessor((row) => row.weight.toFixed(1), {
            header: 'Βάρος (kg)',
            enableEditing: false,
            enableColumnFilter: false, // Disable filtering
        }),
        
        // Disable filtering for 'Πόντοι Επιβράβευσης'
        columnHelper.accessor((row) => row.points.toFixed(1), {
            header: 'Πόντοι Επιβράβευσης',
            enableEditing: false,
            enableColumnFilter: false, // Disable filtering
        }),
        
        columnHelper.accessor('blockchain_id', {
            header: 'Blockchain ID',
            enableEditing: false,
        }),
        columnHelper.accessor('_id', {header: 'ID', enableEditing: false}),
    ];
    


    const tableColumns = [
        {header: '', accessor: 'type'},
        {header: 'Βάρος', accessor: 'totalWeight', decimals: 1},
        {header: 'Πόντοι', accessor: 'totalPoints', decimals: 0},
    ];

    // const getPieData = (period, serial, rfid, userId, dateFrom, dateTo, type) => {
    //     if (user?.role === 'admin' || user?.role === 'superadmin') {
    //         retrievePieData(period, serial, rfid, userId, dateFrom, dateTo, type)
    //             .then((res) => {
    //                 const pieData = Object.entries(res.data).map(([name, value]) => {
    //                     return {value, name};
    //                 });
    //                 setInitPieData(pieData);
    //             });
    //     } else {
    //         retrievePieDataSimpleUser(period, serial, rfid, userId, dateFrom, dateTo, type)
    //             .then((res) => {
    //                 const pieData = Object.entries(res.data).map(([name, value]) => {
    //                     return {value, name};
    //                 });
    //                 setInitPieData(pieData);
    //             });
    //     }
    // };

    const getPieData = (period, serial, rfid, userId, dateFrom, dateTo, type) => {
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrievePieData(period, serial, rfid, userId, dateFrom, dateTo, type)
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData);
                });
        } else {
            retrievePieDataSimpleUser(period, serial, rfid, userId, dateFrom, dateTo, type)
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData);
                });
        }
    };
    

    const openModal = () => {
        const dateFilter = columnFilters.filter(item => item.id === 'date')[0];
        const userFilter = columnFilters.filter(item => item.id === 'user')[0];
        const rfidFilter = columnFilters.filter(item => item.id === 'rfid')[0];
        const serialFilter = columnFilters.filter(item => item.id === 'serial_num')[0];
        const typeFilter = columnFilters.filter(item => item.id === 'type')[0];

        const rfid = rfidFilter?.value ?? '';
        const user = userFilter?.value ?? '';
        const serial = serialFilter?.value ?? '';
        const type = typeFilter?.value ?? '';

        const dates = dateFilter?.value?.map(item => {
            const dateTimeString = item?.$d?.toLocaleString('en-GB');
            if (!dateTimeString) return '';

            const [date, time] = dateTimeString.split(', ');
            return date ? date.split('/').reverse().join('-') : '';
        }) ?? [];

        getPieData(selectedPeriod, serial, rfid, user, dates[0], dates[1], type);

        getTableTotals(selectedPeriod, rfid, user, dates[0], dates[1], serial, type)
            .then((res) => {
                setTableData(res.data);
                setIsOpen(true);
            });
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    
    // Export to Excel Function
    const exportToExcel = () => {
        const filteredRows = table.getFilteredRowModel().rows;
    
        // Get all the current filters from columnFilters
        const dateFilter = columnFilters.find(item => item.id === 'date');
        const userFilter = columnFilters.find(item => item.id === 'user');
        const rfidFilter = columnFilters.find(item => item.id === 'rfid');
        const typeFilter = columnFilters.find(item => item.id === 'type');  // Filter for Ανακυκλώσιμο Υλικό
        const serialNumFilter = columnFilters.find(item => item.id === 'serial_num');  // Filter for Κάδος (serial number)
    
        // Map filters to a readable format for the Excel file (in Greek)
        const filters = {
            date: dateFilter ? dateFilter.value.map(d => new Date(d).toLocaleDateString('el-GR')).join(' - ') : 'Χωρίς φίλτρο ημερομηνίας',
            user: userFilter ? userFilter.value : 'Χωρίς φίλτρο χρήστη',
            rfid: rfidFilter ? rfidFilter.value : 'Χωρίς φίλτρο RFID',
            type: typeFilter ? typeFilter.value : 'Χωρίς φίλτρο Ανακυκλώσιμου Υλικού',  // Greek translation for Ανακυκλώσιμο Υλικό
            serial_num: serialNumFilter ? serialNumFilter.value : 'Χωρίς φίλτρο Κάδου'  // Greek translation for Κάδος (serial number)
        };
    
        // Define the Excel headers and data based on filtered table data
        const wsData = [
            // Header with filter information
            ['Εφαρμοσμένα φίλτρα'],
            [`Ημερομηνία: ${filters.date}, Χρήστης: ${filters.user}, RFID: ${filters.rfid}, Ανακυκλώσιμο Υλικό: ${filters.type}, Κάδος: ${filters.serial_num}`],
            [],
            // Headers for the actual data
            [
                'Χρήστης',
                'RFID',
                'Κάδος',
                'Ανακυκλώσιμο Υλικό',
                'Ημερομηνία',
                'Ωρα',
                'Βάρος (kg)',
                'Πόντοι Επιβράβευσης',
                'Blockchain ID',
                'ID'
            ],
            // Data rows from the filteredRows
            ...filteredRows.map(row => [
                row.original.user || 'N/A',  // Fallback in case user is missing
                row.original.rfid || 'N/A',  // Fallback for RFID
                row.original.serial_num || 'N/A',  // Ensure Κάδος (serial_num) appears
                row.original.type || 'N/A',  // Include Ανακυκλώσιμο Υλικό in the data
                new Date(row.original.date).toLocaleDateString('el-GR'),
                row.original.time || 'N/A',  // Ensure time is handled
                Number(row.original.weight?.toFixed(1)) || 0,  // Handle weight formatting
                Number(row.original.points?.toFixed(1)) || 0,  // Handle points formatting
                row.original.blockchain_id || 'N/A',  // Ensure Blockchain ID appears
                row.original._id || 'N/A'  // Ensure ID appears
            ])
        ];
    
        // Create a new worksheet
        const ws = XLSX.utils.aoa_to_sheet(wsData);
    
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Filtered Data');
    
        // Export the workbook
        XLSX.writeFile(wb, 'Δεδομένα.xlsx');
    };
    

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        enableEditing: user?.role === 'superadmin',
        editDisplayMode: 'row',
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        localization: {...MRT_Localization_EL},
        onColumnFiltersChange: setColumnFilters,
        state: {columnFilters},
        onEditingRowSave: ({table, values}) => {
            const body = {
                type: values.type
            };
            updateWeight(values._id, body).then((_) => {
                toast.success("Επιτυχής Ενημέρωση");
            });
            table.setEditingRow(null);
        },
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',  // Align to the right
                }}
            >
                <FormControl style={{width: '80px', margin: '0 20px'}}>
                    <NativeSelect
                        onChange={(event) => {
                            setSelectedPeriod(event.target.value);
                            if (columnFilters.filter(item => item.id === 'date')[0]) {
                                columnFilters.filter(item => item.id === 'date')[0] = undefined
                            }
                        }}
                        inputProps={{
                            name: 'Σύστημα',
                            id: '',
                        }}
                        value={selectedPeriod}
                    >
                        <option value='all'>Όλα</option>
                        <option value='d'>Ημέρας</option>
                        <option value='w'>Εβδομάδας</option>
                        <option value='m'>Μήνα</option>
                    </NativeSelect>
                </FormControl>

                {/* Tooltip with Info Icon */}
                <Tooltip title={<span style={{fontSize: '14px'}}>Selecting a different period will update the table and charts below. </span>}>
                    <IconButton>
                        <InfoIcon style={{color: '#006699'}}/>
                    </IconButton>
                </Tooltip>

                {/* Export to Excel IconButton */}
                <Tooltip title={<span style={{fontSize: '14px'}}>Λήψη Excel</span>}>
                    <IconButton onClick={exportToExcel}>
                        <BsFiletypeXlsx style={{color: '#006699'}}/>
                    </IconButton>
                </Tooltip>


                <Tooltip title={<span style={{fontSize: '14px'}}>Προβολή Στατιστικών Βάση Φίλτρων.</span>}>
                    <IconButton onClick={openModal}>
                        <QueryStatsIcon style={{color: '#006699'}}/>
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return (
        <div style={{height: '92vh'}}>
            <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                style={{marginLeft: '20px'}}
            >
                Σελίδα Καταχωρίσεων
            </Typography>
            <Box m="20px">
                <Box display={smScreen ? "flex" : "block"} flexDirection={smScreen ? "row" : "column"}
                     justifyContent={smScreen ? "space-between" : "start"} alignItems={smScreen ? "center" : "start"}
                     m="10px 0">
                </Box>

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid xs={12} sm={12} md={8} lg={12} container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid xs={12}>
                            <Box backgroundColor={colors.primary[400]} style={{borderRadius: '10px', width: '85vw'}}>

                                <Box style={{
                                    padding: '10px',
                                    maxWidth: '100%',
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <MaterialReactTable table={table}/>
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Modal show={modalIsOpen}
                   onHide={closeModal}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <CustomModal title={'Στατιστικά'} closeModal={closeModal}>
                    <Box mt="25px" p="30px" display="flex" justifyContent="center" alignItems="center">
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             color={colors.grey[100]}
                             p="15px"
                             style={{
                                 position: 'relative',
                                 width: '500px',
                                 border: '1px solid gray',
                                 borderRadius: '10px'
                             }}>
                            <CustomPieChart
                                title={'Κατανομή Απορριμάτων'}
                                subtitle={'(Φιλτραρισμένο)'}
                                initData={initPieData}
                                getPieData={getPieData}/>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             color={colors.grey[100]}
                             p="15px"
                             style={{
                                 width: '500px',
                                 height: '380px',
                                 margin: '0 20px',
                                 border: '1px solid gray',
                                 borderRadius: '10px',
                                 position: 'relative',
                             }}>
                            <TableComponent data={tableData} columns={tableColumns}/>
                        </Box>
                    </Box>
                </CustomModal>
            </Modal>

        </div>
    );
};

export default Entries;
