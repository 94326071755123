import React from 'react';
import '../css/TableComponent.css';

const TableComponent = ({data, columns}) => {
    return (
        <div className="table-container">
            <table className="compact-table">
                <thead>
                <tr>
                    {columns.map((col, index) => (
                        <th key={index}>{col.header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((col, colIndex) => (
                            <td key={colIndex}>{typeof row[col.accessor] === 'number'
                                ? row[col.accessor].toFixed(col.decimals)
                                : row[col.accessor]}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
