import axios from 'axios';

let config = {
    baseURL: 'https://katapi.enssol.tech'
};

const _axios = axios.create(config);
const allowedPaths = ["/signin", "/forgot-password", "/detail"]
_axios.interceptors.request.use(
    function (config) {
        if (!config.url.includes('/api/signin') || !config.url.includes('/api/signup')) {
            if (localStorage.token || sessionStorage.token) {
                let token = localStorage.token ? localStorage.token : sessionStorage.token
                config.headers.Authorization = 'Bearer ' + token;
            }
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response == null) {
            localStorage.clear()
            window.location.href = '/signin'

            return Promise.reject(error);
        } else if (error.response.status === 401) {
            let currentPath = window.location.pathname
            if (allowedPaths.includes(currentPath)) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export const axiosInstance = _axios;
