import {axiosInstance} from "../boot/axios";


export async function userSignUp(body) {
    return await axiosInstance.post("/api/signup", body)
}


export async function userSignIn(body) {
    return await axiosInstance.post("/api/signin", body)
}


export async function userAccountActivation(body) {
    return await axiosInstance.post("/api/account-activation", body)
}


export async function userForgotPassword(body) {
    return await axiosInstance.put("/api/forgot-password", body)
}


export async function userResetPassword(body) {
    return await axiosInstance.put("/api/reset-password", body)
}

export async function userUpdate(userId, body) {
    return await axiosInstance.put(`/api/user/${userId}`, body)
}

export async function userInfo(userId) {
    return await axiosInstance.get(`/api/user/${userId}`)
}

export async function retrieveUsers() {
    return await axiosInstance.get("/api/retrieve_users")
}

export async function createUser(body) {
    return await axiosInstance.post("/api/user/create_user", body)
}


export async function generateSearchEntriesPdf(data) {
    return await axiosInstance.get(`/api/generateSearchEntriesPdf`, {
        responseType: 'blob',
        params: {
            data: JSON.stringify(data)  // This should work without explicit encoding
        }
    });
}



export async function getReport(period, userId) {
    return await axiosInstance.get(`api/generateReport?period=${period}&userId=${userId}`,{
        responseType: 'blob'
    })

}export async function getReportPdf(period, userId) {
    return await axiosInstance.get(`api/generateReport?period=${period}&userId=${userId}&format=pdf`,{
        responseType: 'blob'
    })
}
